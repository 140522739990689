const adminKeys = [
  "Enter",
  "ArrowRight",
  "ArrowUp",
  "ArrowLeft",
  "ArrowDown",
  "Tab",
];

const goToNextPage = (e) => {
  e.preventDefault();
  if (
    e.type === "mouseup" ||
    e.code === "Space" ||
    adminKeys.includes(e.code)
  ) {
    window.location.href = "entry.html";
  }
};

document.addEventListener("mouseup", goToNextPage);
document.addEventListener("keyup", goToNextPage);
